import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import '../assets/styles/components/header.scss';

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  if (isPartiallyCurrent) {
    return { className: 'nav-link active' };
  }
  return { className: 'nav-link' };
};

/* eslint-disable react/jsx-props-no-spreading */
const PartialNavLink = (props) => (
  <Link getProps={isPartiallyActive} {...props} />
);

const Navigation = ({ showSubscribe, setShowSubscribe, showActions }) => (
  <div className="mobile-nav">
    <PartialNavLink to="/editions">Editions</PartialNavLink>
    {showActions && <PartialNavLink to="/actions">Actions</PartialNavLink>}
    <Link to="/about" activeClassName="active" className="nav-link">
      About
    </Link>
    <div className="last-links">
			<a href="https://f52fb723.sibforms.com/serve/MUIFAEwVMPPbjlWJUY480LXbvJVuk73UUVFgAdDgCmS60DZcMQV3ma-Z89LSDGqcf7obELvrroUSFLTVp0WFlSuvYqjn38YPPR8kWokn2vzvVbIFT_uCusfhHsf1T0YuYAk9nrrZXaSA1TzwSSn-8xEtNUsPU_b86OKgeSSQIMKL0XZwMoYpQjjMedWZNZ6qp0SMEkZgYVt3YkCa" target="_blank" className="nav-link" rel="noreferrer">
				Subscribe
			</a>
      <a href="https://longformeditions.bandcamp.com/" target="_blank" className="nav-link" rel="noreferrer">
        Bandcamp
      </a>
    </div>
  </div>
);

Navigation.propTypes = {
  showSubscribe: PropTypes.bool.isRequired,
  setShowSubscribe: PropTypes.func.isRequired,
  showActions: PropTypes.bool.isRequired,
};

export default Navigation;
